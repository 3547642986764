import { QueryClient } from "@tanstack/react-query";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { QuoteAPI } from "./QuoteAPI";
import { AppStateInterface } from "../contexts/AppLayer";
import { DebounceRecord } from "../types/Quote.interface";

export class EmailUtils {
    queryClient: QueryClient;
    underwriter: PetUnderwriterType;
    quoteApi: QuoteAPI;

    constructor(underwriter: PetUnderwriterType, queryClient: QueryClient) {
        this.queryClient = queryClient;
        this.underwriter = underwriter;
        this.quoteApi = new QuoteAPI(underwriter);
    }

    async verifyEmailRegistration(email: string): Promise<boolean> {
        const cacheKey = ["verifyEmail", email];
        // Try to get the cached result
        const cachedResult = this.queryClient.getQueryData<boolean>(cacheKey);
        if (cachedResult !== undefined) {
            // Return the cached result if available
            return cachedResult;
        }
        try {
            // Perform the API call
            const emailIsRegistered = await this.quoteApi.verifyEmailIsRegistered(email);
            // Cache the result
            this.queryClient.setQueryData(cacheKey, emailIsRegistered);
            return emailIsRegistered;
        } catch (error) {
            // Handle the error (log, rethrow, or return a default value)
            console.error("Error verifying email", error);
            throw error;
        }
    }

    async validateEmail(email: string): Promise<{ typoFixSuggestion: string }> {
        const cacheKey = ["verifyEmailDomainExtension", email];
        // Try to get the cached result
        const cachedResult: { typoFixSuggestion: string } | undefined = this.queryClient.getQueryData(cacheKey);
        if (cachedResult !== undefined) {
            // Return the cached result if available
            return cachedResult;
        }
        try {
            // Perform the API call
            const debounceResponse = await this.quoteApi.verifyEmailDomainExtensionIsValid(email);
            // Return the result
            return { typoFixSuggestion: debounceResponse.typoFixSuggestion };
        } catch (error) {
            // Handle the error (log, rethrow, or return a default value)
            console.error("Error validating email", error);
            throw error;
        }
    }

    async handleEmailDebounce({
        email,
        debounce,
        updateAppState
    }: {
        email?: string;
        debounce?: DebounceRecord;
        updateAppState: (state: Partial<AppStateInterface>) => void;
    }): Promise<boolean> {
        if (!email) return true; // Early return if email is undefined
        const emailMatchesSuggestion = Object.values(debounce || {}).some(entry => entry?.typoFixSuggestion === email);
        if (!!email && !emailMatchesSuggestion) {
            const userRespondedToDebounce = debounce?.[email]?.userResponse !== undefined;
            if (!userRespondedToDebounce) {
                try {
                    const debounceResponse = await this.validateEmail(email);
                    if (debounceResponse.typoFixSuggestion) {
                        const currentDebounceEntry = debounce?.[email];

                        if (!currentDebounceEntry) {
                            updateAppState({ debounceObject: { ...debounce, [email]: debounceResponse } });
                        }

                        updateAppState({ isQuoteUpdating: false });
                        return false;
                    }
                } catch (error) {
                    console.error("Error validating email", error);
                }
            }
        }
        return true;
    }
}
