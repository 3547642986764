// packages
import React, { useCallback, useEffect, useState, useRef, useMemo, FunctionComponent, ReactNode } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDeepCompareMemo, useMediaQuery } from "@react-hookz/web";

// components
import { Button } from "@/shared/components/ui/Button";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";
import { FormField } from "@/shared/components/FormField";
import LoaderWithText from "@/shared/components/LoaderWithText";
import { ReimbursementTermsModal } from "@/shared/components/ReimbursementTermsModal";
import { CoverageIconGrid } from "@/shared/components/CoverageIconGrid";
import { PreventiveCards } from "@/shared/components/PreventiveCards";
import { Heading } from "@/shared/components/ui";
import AdditionalPlans from "@/shared/components/AdditionalPlans";
import MultipetToggle from "@/shared/components/MultipetToggle";
import { PriceInfo } from "./PriceInfo";
import DynamicOptionsField from "@/shared/components/DynamicOptionsField";

// utils
import { CoverageUtils, CoverageValue } from "@/shared/utils/CoverageUtils";
import { MAX_PET_NAME_LABEL } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { PresetCoverageLevel, PreventiveConfig } from "@/shared/utils/CoverageUtils";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useModal } from "../hooks/useModal";
import { usePetPlans } from "@/shared/hooks/usePetPlans";

// types
import { CoverageSettings, Option, Quote, Pet } from "@/shared/types/Quote.interface";
import { FormStepProps } from "@/shared/types/Form";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { DialogProps } from "@/shared/types/Modals";

// media
import InfoIcon from "@/shared/media/icons/CircleInfoProRegular.svg";
import TakeoverProvision from "./TakeoverProvision";
import { CustomizationSlot } from "./CustomizationSlot";

export type CoverageModalTerms = "reimbursement" | "annualLimit" | "annualDeductible";

type CustomCoverageProps = {
    editorConfig: {
        formId: string;
        title?: string;
        coveragePresetData?: PresetCoverageLevel[];
        termsInModal?: CoverageModalTerms[];
        samplePolicyUrl?: string;
        modalContent: React.ComponentType<DialogProps>;
        preventiveConfig: PreventiveConfig;
        includeTransactionFee?: boolean;
        exclusions?: ReactNode;
        customizationSlot?: ReactNode;
        showTakeoverProvision?: boolean;
    };
};

type CoverageEditorProps = FormStepProps<Quote> & CustomCoverageProps;

export function CoverageEditor(props: CoverageEditorProps) {
    const {
        formId,
        title = "Create your plan",
        coveragePresetData,
        termsInModal = ["annualDeductible", "annualLimit", "reimbursement"],
        samplePolicyUrl = "",
        modalContent,
        preventiveConfig,
        includeTransactionFee = true,
        exclusions,
        customizationSlot,
        showTakeoverProvision = false
    } = props?.editorConfig || {};
    const modal = useModal();

    // local state
    const [customCoverageSettings, setCustomCoverageSettings] = useState<CoverageValue | null>(null);
    const [activePetIndex, setActivePetIndex] = useState(0);
    const coverageLevelIndex = CoverageUtils.DEFAULT_COVERAGE_INDEX;

    const initialRef = useRef(false);
    const multiPetLinkedRef = useRef(true);

    // form state
    const { setValue, getValues, watch } = useFormParentContext<Quote>();

    const policies = watch("policies");

    // hooks
    const { appState, updateAppState } = useAppLayerContext();
    const { isMultiPetLinked, updateQuote, isQuoteUpdating, quoteQuery, isApplyAllHidden } = appState;
    const { data: quote } = quoteQuery || {};
    const underwriter = props?.value?.underwriter;
    const isLargeBreakpoint = useMediaQuery("only screen and (min-width : 1024px)");
    const { petPlansQuery: petPlans } = usePetPlans(quote, underwriter as PetUnderwriterType);

    // Initial data
    const allPets = useDeepCompareMemo(() => policies ?? [], [policies]);
    const activePet = useDeepCompareMemo(() => policies?.[activePetIndex], [policies, activePetIndex]);
    const isAnnualBilling = appState.isAnnualBilling;
    const activePetAllPlans = useMemo(() => petPlans?.data?.[activePet?.id ?? ``] ?? [], [activePet?.id, petPlans?.data]);
    const policyPets: Option[] = allPets.map(pet => ({ value: pet.id ?? ``, label: pet.name ?? `` }));
    const priceInfoData = useMemo(
        () => CoverageUtils.getPriceInfoData({ value: quote, includeTransactionFee: false, petIdToShow: !isMultiPetLinked ? activePet?.id : undefined }),
        [activePet?.id, isMultiPetLinked, quote]
    );

    // format pet plan API data for UI components
    const allCoverageOptions = useMemo(
        () => (!!activePetAllPlans.length ? CoverageUtils.formatCustomCoverageData(activePetAllPlans, isAnnualBilling) : null),
        [activePetAllPlans, isAnnualBilling]
    );

    const { coverageTypes, annualLimits, annualDeductibles, reimbursementRates, preventiveCareOptions } = allCoverageOptions || {};

    // find options that have multiple values for matching multipet coverage settings
    const coverageValuesToMatch = useMemo(() => {
        if (!!allCoverageOptions) {
            const valuesWithMultipleOptions = CoverageUtils.findKeysWithMultipleOptions(allCoverageOptions);
            const matchCriteriaKeys = CoverageUtils.convertKeysToMatchCriteria(valuesWithMultipleOptions);
            return matchCriteriaKeys;
        }
        return [];
    }, [allCoverageOptions]);

    const allPetsHaveCoverageSettings = allPets.every(pet => {
        const amounts = pet?.coverageSettings?.amounts;
        return amounts && Object.values(amounts).every(value => value !== undefined) && !!pet.basePrice?.value;
    });

    const transactionFee = includeTransactionFee ? props?.value?.transactionFee?.value ?? 0 : 0;
    const coveragePresets = useMemo(() => {
        if (!!activePet?.id && !!petPlans?.data && !!coveragePresetData) {
            const activePetPlans = petPlans.data[activePet.id];
            if (!!activePetPlans) {
                return CoverageUtils.getPresetPlansFromApiData({ petPlans: activePetPlans, presetCoverageLevels: coveragePresetData });
            }
        }
        return [];
    }, [activePet?.id, coveragePresetData, petPlans?.data]);

    const allPetsHaveSameCoverages = useMemo(() => {
        if (!!allPets.length) {
            const allCoverageSettings = allPets.map(pet => pet?.coverageSettings);
            if (!!allCoverageSettings.length) {
                return CoverageUtils.allPetCoverageSettingsMatch(allCoverageSettings, coverageValuesToMatch);
            }
        }
        return false;
    }, [allPets, coverageValuesToMatch]);

    const allPetsHaveFetchedPlans = useMemo(() => {
        if (!!petPlans?.data) {
            const allPlanIds = Object.keys(petPlans.data);
            return allPets.every(pet => !!pet.id && allPlanIds.includes(pet.id));
        }
        return false;
    }, [allPets, petPlans?.data]);

    const activePetCoverage = useDeepCompareMemo(() => {
        const coverage = !!activePet && customCoverageSettings ? customCoverageSettings : coveragePresets[coverageLevelIndex];
        return coverage;
    }, [customCoverageSettings, coveragePresets, coverageLevelIndex, activePet]);

    const getWellnessLevel = (coverageSettings?: CoverageSettings) => {
        if (!coverageSettings) return "None";
        const wellnessCoverage = coverageSettings?.coverages?.find(c => c.type?.includes(`wellness`))?.type;
        return wellnessCoverage === "wellness-gold" ? "wellness-gold" : wellnessCoverage === "wellness-platinum" ? "wellness-platinum" : "None";
    };

    const [coverageLevel, setCoverageLevel] = useState(
        activePet?.coverageSettings?.coverages?.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly"
    );
    const [annualLimitValue, setAnnualLimitValue] = useState(activePet?.coverageSettings?.amounts?.annualLimit?.toString());
    const [annualDeductibleValue, setAnnualDeductibleValue] = useState(activePet?.coverageSettings?.amounts?.annualDeductible?.toString());
    const [reimbursementValue, setReimbursementValue] = useState(activePet?.coverageSettings?.amounts?.reimbursementRate?.toString());
    const [wellnessValue, setWellnessValue] = useState(getWellnessLevel(activePet?.coverageSettings));

    const updatePricing = useCallback(
        (newCoverageSettings: CoverageSettings) => {
            if (!!petPlans?.data) {
                const calculatedPriceData = CoverageUtils.calculatePriceData({
                    coverageSettings: newCoverageSettings,
                    policies: allPets,
                    isMultiPetLinked,
                    activePetId: activePet?.id,
                    petPlans: petPlans.data,
                    coverageValuesToMatch,
                    isAnnualBilling,
                    transactionFee
                });
                updateAppState({ calculatedPriceData });
            }
        },
        [petPlans?.data, allPets, isMultiPetLinked, activePet?.id, coverageValuesToMatch, isAnnualBilling, transactionFee, updateAppState]
    );

    const updateCoverageSettings = useCallback(
        (newCoverageSettings: CoverageSettings | null) => {
            if (!newCoverageSettings) return;
            setCustomCoverageSettings({ name: `custom`, config: newCoverageSettings });

            updatePricing(newCoverageSettings);

            const { policies } = getValues();
            if (!!policies?.length) {
                if (isMultiPetLinked) {
                    policies.forEach((policy, index) => {
                        setValue(`policies.${index}.coverageSettings`, newCoverageSettings);
                    });
                } else {
                    setValue(`policies.${activePetIndex}.coverageSettings`, newCoverageSettings);
                }
            }

            const allValues = getValues();
            updateQuote?.mutate(allValues);
        },
        [activePetIndex, getValues, isMultiPetLinked, setCustomCoverageSettings, setValue, updatePricing, updateQuote]
    );

    const handleCustomAmounts = (change: { setting: "annualLimit" | "reimbursementRate" | "annualDeductible"; value: string }) => {
        if (!activePetCoverage || !activePetAllPlans) {
            console.error("Active pet or plans data is undefined.");
            return;
        }

        const updatedAmounts = {
            ...activePetCoverage.config?.amounts,
            [change.setting]: Number(change.value)
        };

        const updatedCoverageSettings: CoverageSettings = {
            ...activePetCoverage.config,
            amounts: updatedAmounts
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handleCoverageLevelChange = (planLevel: "accidentOnly" | "accidentAndIllness") => {
        let newCoverages = [...(activePet?.coverageSettings?.coverages || [])];

        // Adjust coverage based on the coverageLevel change
        const hasIllnessCoverage = newCoverages.some(c => c.type === "illness");
        if (planLevel === "accidentAndIllness" && !hasIllnessCoverage) {
            // Push "illness" coverage to newCoverages with a temporary filler ID
            newCoverages.push({ type: "illness", id: "tempId", name: "Illness Coverage" });
        } else if (planLevel === "accidentOnly" && hasIllnessCoverage) {
            // Remove "illness" coverage if switching back to accidentOnly
            newCoverages = newCoverages.filter(c => c.type !== "illness");
        }

        const updatedCoverageSettings: CoverageSettings = {
            ...activePet?.coverageSettings,
            coverages: newCoverages
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handlePreventiveChange = (wellnessType: string) => {
        // When wellnessType is "None", filter out all wellness coverages, otherwise construct new wellness coverage settings
        const isRemovingWellness = wellnessType === "None";

        // Clone existing coverages, excluding any existing wellness coverage if "None" or updating to the new selection
        const newCoverages = (activePet?.coverageSettings?.coverages || []).filter(coverage => !coverage.type?.includes("wellness"));

        if (!isRemovingWellness && (wellnessType === "wellness-gold" || wellnessType === "wellness-platinum")) {
            const newCoverageSettings: CoverageSettings = {
                ...activePet?.coverageSettings,
                coverages: [{ type: wellnessType }]
            };

            const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, newCoverageSettings, coverageValuesToMatch);
            if (matchingPlan) {
                const wellnessRider = CoverageUtils.getRiderFromCoverageType(wellnessType, matchingPlan.riders);
                if (wellnessRider && wellnessRider.id) {
                    // Add the matched wellness coverage with dynamically found id
                    newCoverages.push({ type: wellnessType, id: wellnessRider.id, preventivePrice: wellnessRider.basePrice?.value });
                } else {
                    console.error("No matching wellness rider found for selected plan.");
                }
            } else {
                console.error("No matching plan found for updated wellness coverage settings.");
            }
        }

        // Proceed to update regardless of whether a new wellness plan was added or all wellness coverages were removed
        const updatedCoverageSettings = { ...activePetCoverage?.config, coverages: newCoverages };
        updateCoverageSettings(updatedCoverageSettings);
    };

    const handleActivePetChange = (value: string) => {
        const newActivePetIndex = policyPets.findIndex(pet => pet.value === value);
        setActivePetIndex(newActivePetIndex);
        const selectedPetConfig = allPets[newActivePetIndex]?.coverageSettings;

        if (selectedPetConfig) {
            if (selectedPetConfig?.amounts?.annualLimit) {
                setAnnualLimitValue(selectedPetConfig.amounts.annualLimit.toString());
            }
            if (selectedPetConfig?.amounts?.annualDeductible) {
                setAnnualDeductibleValue(selectedPetConfig.amounts.annualDeductible.toString());
            }
            if (selectedPetConfig?.amounts?.reimbursementRate) {
                setReimbursementValue(selectedPetConfig.amounts.reimbursementRate.toString());
            }
            if (selectedPetConfig?.coverages) {
                setCoverageLevel(selectedPetConfig.coverages.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly");
                setWellnessValue(getWellnessLevel(selectedPetConfig));
            }
            setCustomCoverageSettings({ name: "custom", config: selectedPetConfig });
        }
    };

    const handleOpenModal = useCallback(
        (ModalComponent: FunctionComponent<any>, modalProps?: any) => {
            modal.openModal(ModalComponent, modalProps || {});
        },
        [modal]
    );

    useEffect(() => {
        // Initialization
        if (!isQuoteUpdating && allPetsHaveFetchedPlans && !initialRef.current && !!activePet?.coverageSettings) {
            const allPetPlansHaveSameOptions = CoverageUtils.allPetPlansHaveSameOptions(petPlans?.data);
            const isLinked = allPetsHaveSameCoverages && allPetPlansHaveSameOptions;
            updateAppState({ isMultiPetLinked: isLinked });
            multiPetLinkedRef.current = isLinked;

            // If all pets do not have the same coverages, hide the "Apply to all pets" feature
            updateAppState({ isApplyAllHidden: !allPetPlansHaveSameOptions });
            initialRef.current = true;
        }
    }, [activePet?.coverageSettings, allPetsHaveFetchedPlans, allPetsHaveSameCoverages, isQuoteUpdating, petPlans?.data, updateAppState]);

    useEffect(() => {
        multiPetLinkedRef.current = !!isMultiPetLinked;
    }, [isMultiPetLinked]);

    useEffect(() => {
        if (!isQuoteUpdating && allPetsHaveFetchedPlans && initialRef.current && multiPetLinkedRef.current && !allPetsHaveSameCoverages && !!activePet?.coverageSettings) {
            updateCoverageSettings(activePet.coverageSettings);
        }
    }, [activePet?.coverageSettings, allPetsHaveFetchedPlans, allPetsHaveSameCoverages, isMultiPetLinked, isQuoteUpdating, updateCoverageSettings]);

    useEffect(() => {
        if (!customCoverageSettings && !!activePet?.coverageSettings) {
            setCustomCoverageSettings({ name: "custom", config: activePet.coverageSettings });
        }
    }, [activePet?.coverageSettings, customCoverageSettings]);

    return (
        <>
            {!coveragePresets.length || !allPetsHaveCoverageSettings || !activePet || !underwriter ? (
                <div className="flex min-h-screen flex-col gap-7">
                    <Heading level="h1" className="text-lg font-bold">
                        {title}
                    </Heading>
                    <LoaderWithText text="Finding the best coverage options for you..." />
                </div>
            ) : (
                <>
                    <AnimatePresence initial={false} mode="sync">
                        {!isLargeBreakpoint && policyPets?.length > 1 && !isMultiPetLinked && (
                            <motion.div
                                key={`outer-is-linked-${isMultiPetLinked}`}
                                initial={{ height: 0 }}
                                animate={{ height: `auto` }}
                                exit={{ height: 0, transition: { delay: 0.15 } }}
                                transition={{ duration: 0.15 }}
                            >
                                <motion.div
                                    key={`inner-is-linked-${isMultiPetLinked}`}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { delay: 0.15 } }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.15 }}
                                >
                                    {/* Padding needed to prevent child border clipping caused by overflow-x-auto */}
                                    <div className="flex w-full flex-col items-start gap-3 overflow-x-auto px-[2px] py-1 sm:flex-row sm:items-center">
                                        <RadioButtonGroup
                                            initialValue={policyPets[activePetIndex]?.value as string}
                                            onValueChange={value => handleActivePetChange(value)}
                                            options={policyPets.map(pet => ({ ...pet, label: UIUtils.truncateString(pet.label, MAX_PET_NAME_LABEL) }))}
                                            className="h-11"
                                            disabled={isQuoteUpdating}
                                        />
                                    </div>
                                </motion.div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-2">
                            <div className="col-span-1 flex flex-col gap-1 md:flex-row md:items-center md:gap-4">
                                <Heading level="h1" className="text-lg font-bold">
                                    {title}
                                </Heading>
                                {isApplyAllHidden ? null : <MultipetToggle policies={props?.value?.policies} />}
                                <AnimatePresence initial={false} mode="sync">
                                    {isLargeBreakpoint && policyPets?.length > 1 && !isMultiPetLinked && (
                                        <motion.div
                                            key={`outer-is-linked-${isMultiPetLinked}`}
                                            initial={{ height: 0 }}
                                            animate={{ height: `auto` }}
                                            exit={{ height: 0, transition: { delay: 0.15 } }}
                                            transition={{ duration: 0.15 }}
                                        >
                                            <motion.div
                                                key={`inner-is-linked-${isMultiPetLinked}`}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1, transition: { delay: 0.15 } }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.15 }}
                                            >
                                                {/* Padding needed to prevent child border clipping caused by overflow-x-auto */}
                                                <div className="flex w-full flex-col items-start gap-3 overflow-x-auto px-[2px] py-1 sm:flex-row sm:items-center">
                                                    <RadioButtonGroup
                                                        initialValue={policyPets[activePetIndex]?.value as string}
                                                        onValueChange={value => handleActivePetChange(value)}
                                                        options={policyPets.map(pet => ({ ...pet, label: UIUtils.truncateString(pet.label, MAX_PET_NAME_LABEL) }))}
                                                        className="h-11"
                                                        disabled={isQuoteUpdating}
                                                    />
                                                </div>
                                            </motion.div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                            <div className="col-span-1 flex flex-col justify-end gap-1 md:flex-row md:items-center md:gap-4">
                                {isMultiPetLinked ? (
                                    <PriceInfo
                                        variant="all-pets"
                                        activePetId={activePet?.id}
                                        quoteId={quote?.id}
                                        totalPrice={priceInfoData.roundedPrice}
                                        discountsAmount={priceInfoData.discountsAmount}
                                        policiesCount={quote?.policies?.length ?? 0}
                                    />
                                ) : (
                                    <PriceInfo
                                        variant="single-pet"
                                        activePetId={activePet?.id}
                                        quoteId={quote?.id}
                                        totalPrice={priceInfoData.roundedPrice}
                                        discountsAmount={priceInfoData.discountsAmount}
                                        policiesCount={quote?.policies?.length ?? 0}
                                    />
                                )}
                            </div>
                        </div>

                        <React.Fragment key={activePet?.id}>
                            <div className="mt-7 flex flex-col gap-4">
                                {!!coverageTypes && coverageTypes.length > 1 && (
                                    <div className="flex flex-col gap-5">
                                        <FormField>
                                            <RadioButtonGroup
                                                initialValue={coverageLevel}
                                                options={coverageTypes}
                                                onValueChange={value => {
                                                    setCoverageLevel(value);
                                                    handleCoverageLevelChange(value as "accidentOnly" | "accidentAndIllness");
                                                }}
                                                className="whitespace-nowrap"
                                            />
                                        </FormField>
                                    </div>
                                )}
                                {!!activePetCoverage && !!allPets.length && (
                                    <CustomizationSlot
                                        type="icon-grid"
                                        data={quote}
                                        formId={formId}
                                        formStepId="coverage"
                                        fallback={
                                            <CoverageIconGrid
                                                coverageInfo={activePetCoverage.config}
                                                species={activePet?.species}
                                                underwriter={underwriter}
                                                samplePolicyUrl={samplePolicyUrl}
                                                modalContent={modalContent}
                                            />
                                        }
                                    />
                                )}
                            </div>
                            <div className="mt-7 flex flex-col gap-4">
                                <div className="grid gap-3 lg:grid-cols-3">
                                    {!!annualLimits && annualLimits.length > 0 && (
                                        <FormField>
                                            <DynamicOptionsField
                                                id="coverage-annual-limit"
                                                options={annualLimits}
                                                label="Annual Limit"
                                                initialValue={annualLimitValue}
                                                onValueChange={value => {
                                                    setAnnualLimitValue(value);
                                                    handleCustomAmounts({ setting: "annualLimit", value });
                                                }}
                                            />
                                        </FormField>
                                    )}
                                    {!!reimbursementRates && reimbursementRates.length > 0 && (
                                        <FormField>
                                            <DynamicOptionsField
                                                id="coverage-reimbursement-rate"
                                                options={reimbursementRates}
                                                label="Reimbursement Rate"
                                                initialValue={reimbursementValue}
                                                onValueChange={value => {
                                                    setReimbursementValue(value);
                                                    handleCustomAmounts({ setting: "reimbursementRate", value });
                                                }}
                                            />
                                        </FormField>
                                    )}
                                    {!!annualDeductibles && annualDeductibles.length > 0 && (
                                        <FormField>
                                            <DynamicOptionsField
                                                id="coverage-annual-deductible"
                                                label="Annual Deductible"
                                                options={annualDeductibles}
                                                initialValue={annualDeductibleValue}
                                                onValueChange={value => {
                                                    setAnnualDeductibleValue(value);
                                                    handleCustomAmounts({ setting: "annualDeductible", value });
                                                }}
                                            />
                                        </FormField>
                                    )}
                                </div>
                                <div className="flex w-full flex-row justify-center text-xs text-content-secondary sm:mb-0 sm:ml-auto">
                                    <Button
                                        onClick={() => handleOpenModal(ReimbursementTermsModal, { termsInModal })}
                                        className="text-xs"
                                        startDecorator={<InfoIcon className="h-4" />}
                                        variant="ghost"
                                        size="sm"
                                    >
                                        What do these terms mean?
                                    </Button>
                                </div>
                                {modal.render}
                            </div>
                        </React.Fragment>

                        {showTakeoverProvision && <TakeoverProvision />}

                        {!!activePetCoverage && underwriter && !!preventiveCareOptions && preventiveCareOptions.length > 1 && (
                            <div className="mt-7 flex flex-col gap-4 lg:mt-10">
                                <div className="flex flex-col gap-2 text-center">
                                    <Heading level="h2" className="text-lg font-bold">
                                        Add {preventiveConfig.labels.basic} or {preventiveConfig.labels.advanced} Preventive Care Coverage
                                    </Heading>
                                    <p className="text-sm text-content-secondary">
                                        Get fixed reimbursement amounts every year for certain vaccines, wellness exams, and more, with these optional wellness packages.
                                    </p>
                                </div>
                                <PreventiveCards
                                    pet={activePet as Pet}
                                    preventiveOptions={preventiveCareOptions}
                                    preventiveConfig={preventiveConfig}
                                    underwriter={underwriter}
                                    wellnessLevel={wellnessValue}
                                    handleChange={value => {
                                        setWellnessValue(value);
                                        handlePreventiveChange(value);
                                    }}
                                />
                                {!!coverageTypes && coverageTypes.length === 1 && <AdditionalPlans underwriter={underwriter} />}
                                {!!exclusions && <>{exclusions}</>}
                                {!!customizationSlot && <>{customizationSlot}</>}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
